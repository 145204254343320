@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(15rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottomBig {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes wait {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: .1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scroll-down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }

  100% {
    transform: translateY(0);
  }
}

.translate-left {
  opacity: 0;
  transform: translateX(-10rem);
}

.translate-right {
  opacity: 0;
  transform: translateX(10rem);
}

.translate-down {
  opacity: 0;
  transform: translateY(10rem);
}

.hidden {
  opacity: 0;
}

.hidden--modal {
  visibility: hidden;
  opacity: 0;
}

.visible--modal {
  visibility: visible;
  opacity: 1;
}

.hidden__display--none {
  display: none;
}

.lazy-img {
  filter: blur(20px);
}

.background-image {
  z-index: -10;
  -o-object-fit: cover;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}

.mb-sm {
  margin-bottom: 1.5rem;
}

.mb-md {
  margin-bottom: 3rem;
}

.mb-lg {
  margin-bottom: 4rem;
}

.mb-hg {
  margin-bottom: 6.5rem;
}

.mt-sm {
  margin-top: 1.5rem;
}

.mt-md {
  margin-top: 3rem;
}

.mt-lg {
  margin-top: 4rem;
}

.mt-hg {
  margin-top: 6.5rem;
}

.ml-sm {
  margin-left: 1.5rem;
}

.ml-md {
  margin-left: 3rem;
}

.ml-lg {
  margin-left: 4rem;
}

.ml-hg {
  margin-left: 6.5rem;
}

.hover-translate {
  transition: all .2s;
}

.hover-translate:hover {
  transform: translateY(-.2rem);
  box-shadow: 0 1rem 2rem #0003;
}

.hover-translate:active {
  transform: scale(.99)translateY(.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (width <= 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (width <= 56.25em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (width <= 37.5em) {
  html {
    font-size: 43.75%;
  }
}

@media only screen and (width >= 112.5em) {
  html {
    font-size: 62.5%;
  }
}

::selection {
  background-color: #dfcfab;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-Thin.ca091641.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-ThinItalic.c589404a.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-Light.2e272421.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-LightItalic.31a59cc8.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-Regular.ad872c70.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-Italic.37a74cce.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-Bold.f53d8879.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-BoldItalic.ce6dea93.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-Black.cf8d2946.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: local(Lato), url("Lato-BlackItalic.1d06dc67.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Birthstone Bounce;
  src: local(Birthstone Bounce), url("BirthstoneBounce-Medium.06951061.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Birthstone Bounce;
  src: local(Birthstone Bounce), url("BirthstoneBounce-Regular.6d6afb04.ttf") format("truetype");
  font-weight: 500;
}

.heading-4, .heading-3, .heading-2, .heading-1 {
  font-size: "Lato", sans-serif;
  font-weight: 400;
}

.heading-1 {
  font-size: 4.5rem;
  line-height: 1;
}

.heading-2 {
  font-size: 4rem;
  line-height: 1;
}

.heading-3 {
  text-transform: uppercase;
  font-size: 2rem;
}

.heading-4 {
  font-size: 1.9rem;
}

.text-bold {
  font-weight: 500;
}

.to-do {
  color: red;
  background-color: #ff0;
  font-weight: 500;
}

.link-basic {
  color: #876d33;
  font-weight: 500;
  text-decoration: none;
}

.link-basic:hover {
  color: #bf9f58;
  cursor: pointer;
}

.arrow {
  border: 2px solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transition: all .2s ease-out;
  display: inline-block;
  transform: rotate(-45deg);
}

.arrow__up {
  transform: rotate(-135deg);
}

.arrow__left {
  transform: rotate(135deg);
}

.btn, .btn:link, .btn:visited {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #bf9f58;
  border: none;
  border-radius: 1rem;
  align-items: center;
  padding: 1.8rem 3rem;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.btn:hover {
  background-color: #876d33;
  transform: translateY(-.2rem);
  box-shadow: 0 1rem 2rem #0003;
}

.btn:active {
  transform: scale(.99)translateY(.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

@media only screen and (width <= 37.5em) {
  .btn {
    font-size: 2rem;
  }
}

.btn__simple, .btn__simple:link, .btn__simple:visited {
  color: #202020;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-bottom: 2px solid #0000;
  border-radius: 0;
  align-items: center;
  padding: .5rem 2rem;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.btn__simple:hover {
  color: #876d33;
  border-bottom: 2px solid #876d33;
  transform: translateY(-.2rem);
}

.btn__simple:active {
  transform: translateY(.07rem);
}

.card__container {
  perspective: 150rem;
  width: 35rem;
  height: 28rem;
}

.card__side {
  -webkit-backdrop-filter: blur(7px);
  perspective: 150rem;
  backface-visibility: hidden;
  background: #fff6;
  border: 1px solid #fff6;
  border-radius: 16px;
  justify-self: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 30px #0000001a;
}

.card__main {
  color: #404040;
  opacity: 1;
  padding: 2rem;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.card__main--hidden {
  visibility: hidden;
  opacity: 0;
  transform: rotateY(-180deg);
}

.card__main--heading {
  width: 80%;
  height: 5rem;
  margin-bottom: 1rem;
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 1.2;
}

.card__main--subtitle {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 300;
}

.card__main--btn-wrapper {
  justify-content: space-around;
  display: flex;
}

.card__main--facts {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.card__main--factline {
  color: #404040;
}

.card__main--factline:not(:last-child) {
  border-right: 1px solid #404040;
}

.card__main--factline-value {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 500;
  display: block;
}

.card__main--factline-description {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
  display: block;
}

.card__main--factline-icon {
  height: 3rem;
}

.card__main--factline-text {
  font-size: 1.5rem;
}

.card__main--banner {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background-color: #dfcfab;
  border: 1px solid #dfcfab;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 3rem;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: 5%;
  right: 10%;
  transform: translateX(50%)translateY(50%)rotateZ(45deg);
}

.card__main--btn {
  justify-content: center;
  width: 13rem;
  height: 2rem;
  margin: 1rem 0 4rem;
  font-weight: 500;
}

.card__main--btn, .card__main--btn:link, .card__main--btn:visited {
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 20rem;
  align-items: center;
  padding: 1.8rem 3rem;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.card__main--btn:hover {
  transform: translateY(-.1rem);
  box-shadow: 0 1rem 2rem #0003;
}

.card__main--btn:active {
  transform: scale(.99)translateY(.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

.card__main--btn-details {
  color: #bf9f58;
  background-color: #0000;
  border: 2px solid #bf9f58;
}

.card__main--btn-details:hover {
  color: #fff;
  background-color: #bf9f58;
}

.card__main--btn-booking {
  color: #fff;
  background-color: #bf9f58;
  border: 2px solid #bf9f58;
}

.card__main--btn-booking:hover {
  color: #bf9f58;
  background-color: #0000;
}

.card__back {
  color: #404040;
  visibility: hidden;
  opacity: 0;
  padding: 2rem;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

.card__back--active {
  visibility: visible;
  opacity: 1;
  transform: rotateY(0);
}

.card__back--close {
  color: #0000;
  opacity: .8;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.card__back--close:before, .card__back--close:after {
  content: " ";
  background-color: #404040;
  width: 3px;
  height: 2rem;
  position: absolute;
  left: 1rem;
}

.card__back--close:before {
  transform: rotate(45deg);
}

.card__back--close:after {
  transform: rotate(-45deg);
}

.card__back--close:hover {
  opacity: 1;
}

.card__btn--accordion {
  width: 100%;
  transition: all .5s;
}

.card__btn--accordion, .card__btn--accordion:link, .card__btn--accordion:visited {
  color: #202020;
  cursor: pointer;
  background-color: #f9f7f6;
  border: none;
  align-items: center;
  padding: .5rem 3rem;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.card__btn--arrow {
  color: currentColor;
}

.card__accordion {
  max-height: 0;
  transition: max-height .25s ease-out;
  overflow: hidden;
}

.card__details {
  color: #202020;
  text-align: justify;
  hyphens: auto;
  padding: 2rem 0 1rem;
  font-size: 1.5rem;
  line-height: 1.4;
}

.card__warning {
  hyphens: auto;
  align-items: center;
  padding: 0;
  font-size: 1.2rem;
  display: flex;
}

.card__warning--icon {
  height: 3rem;
  margin-right: 1.5rem;
}

.card__warning--text {
  text-align: justify;
}

.carousel-indicator {
  color: #dfcfab;
  cursor: pointer;
  padding: 0 .4rem;
  font-size: 2.5rem;
}

.carousel-indicator:hover {
  color: #bf9f58;
}

.carousel-indicator--active {
  color: #876d33;
}

hr {
  border: 0;
  border-bottom: 1px solid #bf9f584d;
  width: 100%;
  height: 0;
  margin: 0;
  display: block;
}

.modal {
  z-index: 1000;
  background-color: #fff;
  background-image: radial-gradient(at top, #dfcfab4d, #dfcfab4d);
  border-radius: 1rem;
  max-width: 120rem;
  padding: 5rem 6rem;
  transition: all .3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #0000004d;
}

@media only screen and (width <= 37.5em) {
  .modal {
    width: 80vw;
  }
}

@media only screen and (width <= 56.25em) {
  .modal {
    padding: 4rem 5rem;
  }
}

.modal__heading-underline {
  opacity: .25;
  z-index: -10;
  width: 35rem;
  height: 6rem;
  position: absolute;
  top: 5rem;
  left: 2rem;
}

.overlay {
  backdrop-filter: blur(4px);
  z-index: 100;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__header {
  margin-bottom: 4.5rem;
  font-size: 3.25rem;
  line-height: 1.5;
}

.modal__form {
  margin: 0;
}

@media only screen and (width <= 56.25em) {
  .modal__form {
    margin: 0;
  }
}

.modal__form--grid {
  -moz-column-gap: 3rem;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 3rem;
  display: grid;
}

@media only screen and (width <= 37.5em) {
  .modal__form--grid {
    display: block;
  }
}

.modal__form--group.anfrage {
  grid-area: 1 / 2 / -1 / -1;
  align-self: stretch;
}

.modal__form--input {
  color: inherit;
  background-color: #ffffff80;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: .5rem;
  width: 90%;
  padding: .8rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  transition: all .3s;
  display: block;
}

@media only screen and (width <= 37.5em) {
  .modal__form--input {
    width: 100%;
  }
}

.modal__form--input:focus {
  border-bottom: 3px solid #618877;
  outline: none;
  box-shadow: 0 1rem 2rem #0000001a;
}

.modal__form--input:focus:invalid {
  border-bottom: 3px solid #72322a;
}

.modal__form--input::-webkit-input-placeholder {
  color: #aaa;
}

.modal__form--label {
  height: 1.7rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
  font-size: 1.1rem;
  font-weight: 300;
  transition: all .3s;
  display: block;
}

.modal__form--input:placeholder-shown + .modal__form--label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-3.5rem);
}

.modal__form--btn {
  justify-content: center;
  width: 17rem;
  height: 4.5rem;
  margin: 0 auto;
}

.modal__form textarea {
  resize: none;
  width: 50rem;
  height: calc(100% - 4.7rem);
  padding: 1rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 1.25rem;
}

@media only screen and (width <= 56.25em) {
  .modal__form textarea {
    width: 37rem;
  }
}

@media only screen and (width <= 37.5em) {
  .modal__form textarea {
    width: 100%;
    height: 20vh;
  }
}

.modal__form textarea:focus::placeholder {
  color: #0000;
}

.modal__form button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin-top: 1rem;
}

.btn--close-modal {
  color: #bf9f58;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  transition: all .2s;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.btn--close-modal:hover {
  color: #876d33;
}

.modal-banner {
  z-index: 1000;
  background-color: #fff;
  background-image: radial-gradient(at top, #dfcfab4d, #dfcfab4d);
  border-radius: 1rem;
  grid-template-columns: repeat(2, 1fr);
  width: 80rem;
  height: 50rem;
  transition: all .5s;
  display: grid;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #0000004d;
}

@media only screen and (width <= 56.25em) {
  .modal-banner {
    width: 70rem;
  }
}

@media only screen and (width <= 37.5em) {
  .modal-banner {
    width: 80vw;
    height: 80vh;
    display: block;
  }
}

.modal-banner__btn--close-modal {
  color: #bf9f58;
  cursor: pointer;
  z-index: 2000;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  transition: all .2s;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.modal-banner__btn--close-modal:hover {
  color: #876d33;
}

@media only screen and (width <= 37.5em) {
  .modal-banner__btn--close-modal {
    text-align: center;
    background-color: #ffffffb3;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    top: 2rem;
  }
}

.modal-banner__textarea {
  grid-column: 2;
  padding: 6rem 5rem;
}

@media only screen and (width <= 56.25em) {
  .modal-banner__textarea {
    padding: 4rem 3rem;
  }
}

.modal-banner__heading {
  color: #bf9f58;
  font-family: Birthstone Bounce, cursive;
  font-size: 6rem;
  line-height: 1;
}

.modal-banner__text {
  font-size: 1.4rem;
}

.modal-banner__signature {
  color: #bf9f58;
  font-family: Birthstone Bounce, cursive;
  font-size: 3rem;
  line-height: 1;
}

.modal-banner__picture {
  height: 100%;
  transform: translateX(-15rem);
}

@media only screen and (width <= 37.5em) {
  .modal-banner__picture {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    transform: none;
  }
}

.modal-banner__picture--container {
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  grid-column: 1;
  overflow: hidden;
}

@media only screen and (width <= 37.5em) {
  .modal-banner__picture--container {
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
    height: 30vh;
  }
}

.lds-roller__overlay {
  backdrop-filter: blur(4px);
  z-index: 10000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller__checkout {
  z-index: 20000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: #bf9f58;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.about-me {
  grid-column: full-start / full-end;
  grid-template-columns: repeat(5, 1fr);
  padding: 5rem 5rem 2rem;
  scroll-margin-top: calc(7rem - 2px);
  display: grid;
  position: relative;
}

@media only screen and (width <= 56.25em) {
  .about-me {
    display: block;
  }
}

@media only screen and (width >= 112.5em) {
  .about-me {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.about-me__img {
  width: 100%;
}

.about-me__img--wrapper {
  grid-area: 2 / 4 / 5 / -1;
  justify-self: end;
  width: 80%;
}

@media only screen and (width <= 56.25em) {
  .about-me__img--wrapper {
    display: none;
  }
}

.about-me__heading {
  grid-column: 1 / -1;
  font-size: 8rem;
  font-weight: 100;
}

.about-me__container {
  text-align: justify;
  grid-column: 1 / 4;
  font-size: 2rem;
}

@media only screen and (width <= 56.25em) {
  .about-me__container {
    grid-column: 1 / -1;
  }
}

.about-me__contact {
  grid-column: 1 / 4;
  align-self: center;
}

@media only screen and (width <= 37.5em) {
  .about-me__contact {
    grid-column: 1 / -1;
  }
}

.about-me__contact--text {
  font-size: 2rem;
}

.about-me__btn {
  justify-content: center;
  width: 27rem;
  margin-top: 3rem;
}

@media only screen and (width <= 37.5em) {
  .about-me__btn {
    width: 32rem;
  }
}

@media only screen and (width <= 21.875em) {
  .about-me__btn {
    width: 30rem;
    padding-bottom: 2rem;
    font-size: 1.8rem !important;
  }
}

.about-me__carousel-container {
  max-width: 110rem;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.about-me__carousel-container--arrow {
  z-index: 10;
  color: #bf9f58;
  margin: 3rem 0;
  padding-bottom: 2rem;
  font-size: 6rem;
  line-height: 1;
  position: absolute;
  top: 43%;
}

@media only screen and (width <= 37.5em) {
  .about-me__carousel-container--arrow {
    top: 11rem;
  }
}

.about-me__carousel-container--arrow:hover {
  color: #876d33;
  cursor: pointer;
}

.about-me__carousel-container--arrow-back {
  left: 0;
  transform: translate(50%, -50%);
}

@media only screen and (width <= 37.5em) {
  .about-me__carousel-container--arrow-back {
    left: calc(50vw - 17rem);
    transform: translate(50%, -50%);
  }
}

.about-me__carousel-container--arrow-forward {
  right: 0;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 37.5em) {
  .about-me__carousel-container--arrow-forward {
    right: calc(50vw - 17rem);
    transform: translate(-50%, -50%);
  }
}

.about-me__carousel-indicators {
  display: flex;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.about-me__testimonial {
  -moz-column-gap: 2rem;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 2rem;
  width: 80%;
  display: grid;
  position: relative;
}

@media only screen and (width <= 37.5em) {
  .about-me__testimonial {
    grid-template-columns: 1fr;
    row-gap: 3rem;
    width: 86%;
    padding-top: 6rem;
    position: absolute;
    top: 0;
  }
}

.about-me__bewertungen {
  grid-column: 1 / 4;
  height: 33rem;
  margin-top: 0;
}

@media only screen and (width <= 75em) {
  .about-me__bewertungen {
    grid-column: 1 / -1;
    height: 42rem;
  }
}

@media only screen and (width <= 37.5em) {
  .about-me__bewertungen {
    height: 55rem;
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

@media only screen and (width <= 21.875em) {
  .about-me__bewertungen {
    height: 65rem;
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

.about-me__bewertungen--photo {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
}

@media only screen and (width <= 37.5em) {
  .about-me__bewertungen--photo {
    justify-self: center;
    width: 15rem;
    height: 15rem;
  }
}

.about-me__bewertungen--container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  display: flex;
  position: absolute;
  top: 0;
}

.about-me__bewertungen--text {
  hyphens: auto;
  padding-bottom: 1rem;
  font-size: 1.8rem;
}

@media only screen and (width <= 37.5em) {
  .about-me__bewertungen--text {
    font-size: 1.7rem;
  }
}

@media only screen and (width <= 21.875em) {
  .about-me__bewertungen--text {
    text-align: center;
  }
}

.about-me__bewertungen--text-unterschrift {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
}

@media only screen and (width <= 21.875em) {
  .about-me__bewertungen--text-unterschrift {
    text-align: center;
  }
}

.behandlungen {
  padding-top: 5rem;
  scroll-margin-top: calc(7rem - 2px);
}

@media only screen and (width >= 112.5em) {
  .behandlungen {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.behandlungen__heading {
  padding-left: 5rem;
  font-size: 8rem;
  font-weight: 100;
}

.behandlungen__heading-underline {
  opacity: .25;
  z-index: -10;
  width: 45rem;
  height: 6rem;
  position: absolute;
  top: 0;
  left: 2rem;
}

@media only screen and (width <= 37.5em) {
  .behandlungen__heading-underline {
    width: 40rem;
  }
}

@media only screen and (width <= 21.875em) {
  .behandlungen__heading-underline {
    width: 80vw;
  }
}

.behandlungen__container {
  grid-template-columns: [beha-start] repeat(3, [beha-col-start] 1fr[beha-col-end])[beha-end];
  row-gap: 5rem;
  height: 50rem;
  display: grid;
  overflow: hidden;
}

@media only screen and (width <= 37.5em) {
  .behandlungen__container {
    height: auto;
    display: block;
  }
}

.behandlungen__btn {
  width: 22rem;
  height: 3rem;
}

.behandlungen__photo {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-width: 100vw;
  height: 40rem;
  transition: all 1s;
  display: block;
}

@media only screen and (width <= 37.5em) {
  .behandlungen__photo {
    height: 40vh;
    max-height: 30rem;
    margin: 15rem 0 3rem;
  }

  .behandlungen__photo--first {
    margin: 0 0 5rem;
  }
}

.behandlungen__photo--left {
  grid-column: beha-start / 1 beha-col-end;
}

.behandlungen__photo--right {
  grid-column: 3 beha-col-start / beha-end;
}

.behandlungen__textcontainer {
  padding: 0 5rem;
  transition: all 1s;
  position: relative;
}

.behandlungen__textcontainer--text {
  -moz-column-count: 2;
  column-count: 2;
  font-size: 2rem;
}

@media only screen and (width <= 56.25em) {
  .behandlungen__textcontainer--text {
    -moz-column-count: 1;
    column-count: 1;
  }
}

.behandlungen__textcontainer-right {
  grid-area: 1 / 2 beha-col-start / 2 / beha-end;
  padding-right: 5rem;
}

@media only screen and (width <= 37.5em) {
  .behandlungen__textcontainer-right {
    padding: 0 5rem;
  }
}

.behandlungen__textcontainer-left {
  grid-area: 1 / beha-start / 2 / 2 beha-col-end;
  padding-left: 5rem;
}

@media only screen and (width <= 37.5em) {
  .behandlungen__textcontainer-left {
    padding: 0 5rem;
  }

  .behandlungen {
    margin-bottom: 15rem;
  }
}

.behandlungen--active {
  transform: translateX(0);
}

.footer {
  background-image: radial-gradient(at top, #f5e6e480, #dfcfab80);
  grid-column: full-start / full-end;
  height: 45rem;
  padding: 5rem;
  position: relative;
}

@media only screen and (width <= 56.25em) {
  .footer {
    padding: 4rem 5rem;
  }
}

@media only screen and (width <= 37.5em) {
  .footer {
    height: 60rem;
  }
}

@media only screen and (width >= 112.5em) {
  .footer {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.footer__link-container {
  grid-template-columns: repeat(5, max-content);
  justify-content: center;
  align-items: center;
  height: 2rem;
  display: grid;
}

@media only screen and (width <= 37.5em) {
  .footer__link-container {
    text-align: center;
    height: auto;
    display: block;
  }
}

.footer__link-container--element {
  padding: 0 4rem;
}

@media only screen and (width <= 56.25em) {
  .footer__link-container--element {
    padding: 0 3rem;
  }
}

@media only screen and (width <= 37.5em) {
  .footer__link-container--element {
    padding: .2rem 3rem;
  }
}

.footer__link-container--link {
  font-size: "Lato", sans-serif;
  text-transform: uppercase;
  color: #bf9f58;
  cursor: pointer;
  align-items: center;
  padding: .5rem 0;
  font-size: 1.4rem;
  font-weight: 300;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.footer__link-container--link:hover {
  color: #876d33;
  font-weight: 500;
  transform: translateY(-.2rem);
}

@media only screen and (width <= 37.5em) {
  .footer__link-container--link:hover {
    font-size: 2.2rem;
  }
}

.footer__link-container--link:active {
  font-weight: 500;
  transform: scale(.99)translateY(-.1rem);
}

@media only screen and (width <= 37.5em) {
  .footer__link-container--link {
    justify-content: center;
    font-size: 2rem;
  }
}

.footer__bg-image {
  height: 12vw;
  max-height: 22rem;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

@media only screen and (width <= 37.5em) {
  .footer__bg-image {
    width: 70vw;
    height: auto;
  }
}

.footer__logo-aufgehuebscht {
  justify-self: start;
  width: 20rem;
}

.footer__logo-aufgehuebscht--container {
  justify-content: center;
  padding: 2rem 0 4rem;
  display: flex;
}

.footer__social-media--logo-container {
  -moz-column-gap: 2.5rem;
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  column-gap: 2.5rem;
  padding: 3rem 0 4rem;
  display: grid;
}

.footer__social-media--logo {
  fill: #fff;
  height: 4rem;
  transition: all .2s;
}

.footer__social-media--logo:hover {
  transform: scale(1.2)translateY(-.2rem);
}

.footer__social-media--logo:active {
  transform: scale(.99)translateY(.1rem);
}

.footer__copyright {
  color: #bf9f58;
  border-top: 1px solid #bf9f58;
  justify-content: space-between;
  padding-top: 1rem;
  display: flex;
}

@media only screen and (width <= 21.875em) {
  .footer__copyright {
    text-align: center;
    display: block;
  }
}

.container {
  transition: opacity 1s translate 1s;
  display: block;
}

.header {
  background-image: url("bg-start.5f3871e6.jpg");
  background-position: top;
  background-size: cover;
  height: calc(100vh - 7rem);
  min-height: 70rem;
  margin-top: 7rem;
  padding: 0 0 0 7vw;
  scroll-margin-top: calc(7rem - 2px);
}

@media only screen and (width >= 112.5em) {
  .header {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.header__welcome {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
}

.header__welcome--message-1 {
  color: #bf9f58;
  opacity: 0;
  font-family: Birthstone Bounce, cursive;
  font-size: 22rem;
  line-height: 1;
  animation-name: moveInBottom;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@media only screen and (width <= 37.5em) {
  .header__welcome--message-1 {
    font-size: 20rem;
  }
}

@media only screen and (width <= 21.875em) {
  .header__welcome--message-1 {
    font-size: 18rem;
  }
}

.header__welcome--message-2 {
  opacity: 0;
  font-size: 8rem;
  font-weight: 100;
  line-height: 1;
  animation-name: moveInBottom;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.header__welcome--message-main {
  opacity: 0;
  padding: 7vh 0;
  font-size: 3.5rem;
  font-weight: 300;
  animation-name: wait, moveInLeft;
  animation-duration: 2s, 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: 0s, 1s;
  animation-fill-mode: forwards;
  animation-timeline: auto, auto;
}

.header__btn {
  opacity: 0;
  justify-content: center;
  width: 60%;
  animation-name: wait, moveInBottom;
  animation-duration: 2s, 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: 0s, 1s;
  animation-fill-mode: forwards;
  animation-timeline: auto, auto;
  display: flex;
}

@media only screen and (width <= 37.5em) {
  .header__btn {
    width: 80%;
    font-size: 1.8rem !important;
  }
}

@media only screen and (width <= 21.875em) {
  .header__btn {
    width: 90%;
    font-size: 1.8rem !important;
  }
}

.header__scroll-down {
  position: absolute;
  bottom: 2rem;
  left: 50%;
}

@media only screen and (width <= 56.25em) {
  .header__scroll-down {
    display: none;
  }
}

.header__scroll-down--icon {
  height: 3vw;
  min-height: 10rem;
  animation: 2s infinite scroll-down;
}

.navbar {
  -webkit-backdrop-filter: blur(5px);
  z-index: 100;
  grid-column-gap: 2rem;
  background: #ffffffe6;
  border-bottom: 1px solid #dfcfab;
  grid-template-columns: max-content 1fr max-content;
  place-items: center;
  width: 100%;
  height: 7rem;
  padding: 0 4vw;
  font-size: 1.8rem;
  display: grid;
  position: fixed;
  top: 0;
  box-shadow: 0 4px 30px #0000001a;
}

@media only screen and (width >= 112.5em) {
  .navbar {
    padding: 0 calc(50vw - 900px + 5rem);
  }
}

@media only screen and (width <= 75em) {
  .navbar {
    padding-right: 0;
  }
}

@media only screen and (width <= 37.5em) {
  .navbar {
    grid-template-columns: max-content 1fr;
  }
}

.navbar__btn--login {
  color: #bf9f58;
  background-color: #fff;
  box-shadow: inset 0 0 0 4px #bf9f58;
}

.navbar__btn--login:hover {
  color: #fff;
  background-color: #876d33;
  box-shadow: inset 0 0 #bf9f58;
}

@media only screen and (width <= 56.25em) {
  .navbar__btn--login {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (width <= 37.5em) {
  .navbar__btn--login {
    display: none;
  }
}

.navbar__logo {
  cursor: pointer;
  justify-self: start;
  height: 5rem;
}

@media only screen and (width <= 56.25em) {
  .navbar__logo {
    width: 16vw;
    height: auto;
  }
}

@media only screen and (width <= 37.5em) {
  .navbar__logo {
    width: auto;
    height: 5rem;
  }
}

@media only screen and (width <= 75em) {
  .navbar__btn--cta {
    justify-self: end;
  }
}

@media only screen and (width <= 56.25em) {
  .navbar__btn--cta {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (width <= 37.5em) {
  .navbar__btn--cta {
    display: none !important;
  }
}

@media only screen and (width <= 75em) {
  .navbar__btn--section {
    justify-content: flex-end;
    width: 100%;
    padding: .5rem 0;
  }
}

@media only screen and (width <= 37.5em) {
  .navbar__btn--section {
    font-size: 2rem;
  }
}

.navbar__section-buttons {
  grid-column-gap: 0rem;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  display: grid;
}

@media only screen and (width <= 75em) {
  .navbar__section-buttons {
    display: none;
  }
}

.navbar__button--open {
  text-align: center;
  cursor: pointer;
  background-color: #0000;
}

.navbar__icon {
  color: #bf9f58;
  margin-top: 3.5rem;
  position: relative;
}

.navbar__icon, .navbar__icon:before, .navbar__icon:after {
  background-color: #bf9f58;
  width: 3rem;
  height: 2px;
  display: inline-block;
}

.navbar__icon:before, .navbar__icon:after {
  content: "";
  transition: all .2s;
  position: absolute;
  left: 0;
}

.navbar__icon:before {
  top: -.8rem;
}

.navbar__icon:after {
  top: .8rem;
}

.navbar__checkbox {
  display: none;
}

.navbar__nav-dropdown {
  margin-left: 3rem;
  padding-left: 10rem;
  padding-right: 4vw;
  display: none;
  position: relative;
  transform: translateY(-3px);
}

@media only screen and (width <= 75em) {
  .navbar__nav-dropdown {
    display: inline-block;
  }
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-dropdown {
    justify-self: end;
  }
}

.navbar__nav-dropdown--content {
  visibility: hidden;
  z-index: -10;
  background-color: #fff;
  min-width: 160px;
  padding: 8rem 0 2rem 1rem;
  transition: all .3s;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateY(-25rem);
  box-shadow: 0 8px 16px #0003;
}

@media only screen and (width <= 75em) {
  .navbar__nav-dropdown--content {
    padding-left: 4rem;
    padding-right: 4vw;
  }
}

@media only screen and (width <= 56.25em) {
  .navbar__nav-dropdown--content {
    padding-left: 2rem;
  }
}

@media only screen and (width <= 37.5em) {
  .navbar__nav-dropdown--content {
    width: 100vw;
    padding-left: 60vw;
  }
}

.navbar__checkbox:checked ~ .navbar__nav-dropdown--content {
  visibility: visible;
  transform: translateY(0);
}

.navbar__button--open:hover .navbar__icon:before {
  top: -1rem;
}

.navbar__button--open:hover .navbar__icon:after {
  top: 1rem;
}

.navbar__checkbox:checked + .navbar__button--open .navbar__icon {
  background-color: #0000;
}

.navbar__checkbox:checked + .navbar__button--open .navbar__icon:before {
  top: 0;
  transform: rotate(135deg);
}

.navbar__checkbox:checked + .navbar__button--open .navbar__icon:after {
  top: 0;
  transform: rotate(-135deg);
}

.produkte {
  grid-template-rows: min-content 1fr;
  align-items: center;
  min-height: calc(100vh - 7rem);
  padding: 5rem;
  scroll-margin-top: calc(7rem - 2px);
  display: grid;
  position: relative;
}

@media only screen and (width >= 112.5em) {
  .produkte {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.produkte__background {
  z-index: -1;
  justify-content: center;
  align-items: center;
  width: calc(100% + 5rem);
  height: 100%;
  margin-left: -5rem;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.produkte__background--overlay {
  z-index: -1;
  background-image: linear-gradient(to top right, #fff0 0%, #ffffffb3 35%, #ffffffe6 60%);
  width: 100%;
  height: 100%;
  position: absolute;
}

@media only screen and (width <= 37.5em) {
  .produkte__background--overlay {
    background-image: linear-gradient(to top right, #fff0 0%, #ffffffb3 20%, #ffffffe6 40%);
  }
}

.produkte__heading {
  align-self: start;
  font-size: 8rem;
  font-weight: 100;
}

.produkte__heading-underline {
  opacity: .9;
  width: 45rem;
  height: 9rem;
  position: absolute;
  top: 6rem;
  left: 2rem;
}

.produkte__container {
  grid-template-columns: [prod-start] repeat(5, [prod-col-start] minmax(min-content, 1fr)[prod-col-end])[prod-end];
  align-self: center;
  display: grid;
}

.produkte__container--text {
  -moz-column-gap: 5rem;
  grid-column: 4 prod-col-start / prod-end;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 4vh 5rem;
  margin-top: 2vh;
  font-size: 1.6rem;
  display: grid;
}

@media only screen and (width <= 75em) {
  .produkte__container--text {
    grid-column: 3 prod-col-start / prod-end;
  }
}

@media only screen and (width <= 37.5em) {
  .produkte__container--text {
    grid-column: 1 prod-col-start / prod-end;
  }
}

.produkte__container--icon {
  width: 6rem;
  height: 6rem;
}

.produkte--active {
  opacity: 0;
  animation-name: moveInRight;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.produkte--active:first-child {
  animation-delay: .1s;
}

.produkte--active:nth-child(2) {
  animation-delay: .4s;
}

.produkte--active:nth-child(3) {
  animation-delay: .7s;
}

.produkte--active:nth-child(4) {
  animation-delay: 1s;
}

.section {
  transition: transform 1s, opacity 1s;
}

.section--active {
  opacity: 1;
  animation-name: moveInBottomBig;
  animation-duration: 1s;
  animation-timing-function: ease;
}

.maps {
  grid-column: 2 / -1;
  height: 50rem;
}

#map {
  width: 100%;
  height: 100%;
}

.angebot {
  padding: 5rem;
  scroll-margin-top: calc(19rem - 30px);
}

@media only screen and (width >= 112.5em) {
  .angebot {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.angebot__gesichtsbehandlung {
  margin-top: 19rem;
}

.angebot__scrollmenu {
  white-space: nowrap;
  height: 5rem;
  line-height: 2;
  overflow: auto hidden;
}

.angebot__scrollmenu--btn, .angebot__scrollmenu--btn:link, .angebot__scrollmenu--btn:visited {
  color: #bf9f58;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 0;
  margin-right: 4rem;
  padding: .5rem 0;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

.angebot__scrollmenu--btn:hover, .angebot__scrollmenu--btn:active {
  transform: translateY(-.2rem);
}

.angebot__scrollmenu--btn:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: #bf9f58;
  width: 100%;
  height: 2px;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.angebot__scrollmenu--btn:hover:after, .angebot__scrollmenu--active:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.angebot__heading {
  text-transform: uppercase;
  width: 55rem;
  height: 5rem;
  font-size: 3rem;
  font-weight: 100;
  position: relative;
}

@media only screen and (width <= 37.5em) {
  .angebot__heading {
    width: 45rem;
  }
}

.angebot__heading--container {
  width: 100%;
  padding-bottom: 1.5rem;
}

.angebot__header {
  z-index: 90;
  -webkit-backdrop-filter: blur(10px);
  background: #ffffffe6;
  border-bottom: 1px solid #dfcfab;
  width: 100%;
  height: 12rem;
  padding: 2rem 5rem;
  position: fixed;
  top: 7rem;
  box-shadow: 0 4px 30px #0000001a;
}

@media only screen and (width >= 112.5em) {
  .angebot__header {
    padding: 2rem calc(50vw - 900px + 5rem);
  }
}

.angebot__header--heading {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 100;
}

.angebot__wrapper {
  background-color: #fff9;
  margin: 0 -5rem;
  position: relative;
  overflow: hidden;
}

.angebot__wrapper--background {
  z-index: -1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.angebot__btn--accordion {
  z-index: 10;
  width: 100%;
  transition: all .5s;
  position: relative;
}

.angebot__btn--accordion, .angebot__btn--accordion:link, .angebot__btn--accordion:visited {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #bf9f58;
  border: none;
  border-radius: 1rem;
  align-items: center;
  padding: 1.8rem 3rem;
  font-family: Lato, sans-serif;
  font-size: 2rem;
  font-weight: 300;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.angebot__btn--accordion:hover {
  background-color: #876d33;
}

.angebot__btn--arr-closed {
  transform-origin: 0;
  font-size: 2rem;
  font-weight: 700;
  transform: rotateZ(-90deg)translateX(-.3rem);
}

.angebot__btn--arr-open {
  transform-origin: 0;
  transform: rotateZ(90deg)translateX(-.3rem);
}

.angebot__btn--arrow {
  color: #fff;
}

.angebot__accordion {
  z-index: 1;
  -moz-column-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  justify-content: space-between;
  place-items: start center;
  gap: 4rem;
  padding: 6rem 5rem 3rem;
  display: grid;
  transform: translateY(-1rem);
}

.booking__header {
  margin-top: 7rem;
  padding: 5rem;
}

@media only screen and (width >= 112.5em) {
  .booking__header {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.booking__header--heading {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 100;
}

.booking {
  background-color: #fff9;
  padding: 5rem;
  position: relative;
}

@media only screen and (width >= 112.5em) {
  .booking {
    margin: 5rem calc(50vw - 900px + 5rem);
  }
}

@media only screen and (width <= 37.5em) {
  .booking {
    padding: 2rem;
  }
}

.booking__background {
  z-index: -1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -5rem;
  margin-left: -5rem;
  display: flex;
  position: absolute;
  overflow: hidden;
}

@media only screen and (width <= 37.5em) {
  .booking__background {
    width: 100%;
    margin-top: -2rem;
    margin-left: -2rem;
  }
}

.bookings {
  width: 70%;
  margin: 0 auto 3rem;
}

@media only screen and (width <= 75em) {
  .bookings {
    width: 90%;
  }
}

@media only screen and (width <= 37.5em) {
  .bookings {
    width: 100%;
  }
}

.bookings__btn--back, .bookings__btn--back:link, .bookings__btn--back:visited {
  color: #bf9f58;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #bf9f58;
  border-radius: 1rem;
  align-items: center;
  width: 22rem;
  margin-bottom: 2rem;
  padding: .5rem 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.bookings__btn--back:hover, .bookings__btn--back:active {
  color: #fff;
  background-color: #bf9f58;
  transform: translateY(-.1rem);
  box-shadow: 0 1rem 2rem #0003;
}

.bookings__grid {
  grid-template-columns: 2fr repeat(2, 1fr) 5rem;
  align-items: center;
  display: grid;
}

@media only screen and (width <= 37.5em) {
  .bookings__grid {
    grid-template-columns: 3fr repeat(2, minmax(max-content, 1fr)) 2.5rem;
  }
}

@media only screen and (width <= 56.25em) {
  .bookings__grid {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
  }
}

.bookings__first-row {
  -webkit-backdrop-filter: blur(7px);
  background: #fff6;
  border: 1px solid #fff6;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  box-shadow: 0 4px 30px #0000001a;
}

.bookings__row--wrapper {
  -webkit-backdrop-filter: blur(7px);
  background: #fff6;
  border: 1px solid #fff6;
  border-radius: 1rem;
  padding: 1rem 2rem;
  box-shadow: 0 4px 30px #0000001a;
}

.bookings__row--behandlung--kategorie {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
}

.bookings__row--behandlung--name, .bookings__row--element {
  font-size: 1.5rem;
  font-weight: 300;
}

.bookings__row--delete {
  font-size: 1.3rem;
  font-weight: 300;
}

.bookings__row--delete-icon {
  cursor: pointer;
  height: 1.8rem;
}

.bookings__row:not(:first-child) {
  padding-top: .5rem;
}

.bookings__row:not(:last-child) {
  padding-bottom: .5rem;
}

.bookings__summary-row {
  -webkit-backdrop-filter: blur(7px);
  background: #fff6;
  border: 1px solid #fff6;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  box-shadow: 0 4px 30px #0000001a;
}

.booking-calendar {
  width: 70%;
  margin: 0 auto 3rem;
}

.booking-calendar__wrapper {
  -moz-column-gap: 3rem;
  grid-template-columns: max-content 1fr;
  column-gap: 3rem;
  display: grid;
}

@media only screen and (width <= 56.25em) {
  .booking-calendar__wrapper {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 2rem;
  }
}

@media only screen and (width <= 75em) {
  .booking-calendar {
    width: 90%;
  }
}

@media only screen and (width <= 37.5em) {
  .booking-calendar {
    width: 100%;
  }
}

@media only screen and (width <= 56.25em) {
  .calendar__wrapper {
    width: 100%;
  }
}

.time-slots__wrapper {
  -webkit-backdrop-filter: blur(7px);
  background: #fff6;
  border: 1px solid #fff6;
  border-radius: 1rem;
  height: 29rem;
  padding: 2rem;
  transition: all .3s;
  overflow-y: scroll;
  box-shadow: 0 4px 30px #0000001a;
}

@media only screen and (width <= 56.25em) {
  .time-slots__wrapper {
    height: 32rem;
    width: calc(var(--litepicker-month-width)  + 10px);
  }
}

.time-slots__wrapper--active {
  opacity: 1;
}

.time-slot {
  cursor: pointer;
  align-items: center;
  height: 4rem;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  line-height: 4rem;
  transition: all .2s;
  display: flex;
}

@media only screen and (width <= 56.25em) {
  .time-slot {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}

.time-slot:not(:last-child) {
  border-bottom: 1px solid #bf9f584d;
}

.time-slot:hover, .time-slot:active {
  background-color: #bf9f584d;
  border: none;
  border-radius: 1rem;
}

.time-slot__date {
  text-transform: uppercase;
  text-align: center;
  border-bottom: 2px solid #bf9f58e6;
  height: 5rem;
  padding: 1rem 2rem;
  font-size: 1.7rem;
  font-weight: 500;
}

.time-slot__selected, .time-slot__selected:hover, .time-slot__selected:active {
  color: #fff;
  background-color: #bf9f58;
  border-radius: 1rem;
}

.time-slot__not-available {
  color: #bf9f58;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  top: calc(50% - 3rem);
}

.booking__form {
  -webkit-backdrop-filter: blur(7px);
  background: #fff6;
  border: 1px solid #fff6;
  border-radius: 1rem;
  width: 70%;
  margin: 0 auto 3rem;
  padding: 3rem 2rem;
  transition: all .3s;
  box-shadow: 0 4px 30px #0000001a;
}

@media only screen and (width <= 56.25em) {
  .booking__form {
    width: calc(var(--litepicker-month-width)  + 10px);
    margin: 0 auto;
  }
}

.booking__form--active {
  opacity: 1;
}

.booking__form--grid {
  -moz-column-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 3rem;
  display: grid;
}

@media only screen and (width <= 37.5em) {
  .booking__form--grid {
    display: block;
  }
}

@media only screen and (width <= 56.25em) {
  .booking__form--grid {
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
}

.booking__form--group.anfrage {
  grid-column: 1 / -1;
  align-self: stretch;
  width: 100%;
  height: 15rem;
}

.booking__form--input {
  color: inherit;
  background-color: #0000;
  border: 1px solid #bf9f58;
  border-bottom: 3px solid #0000;
  border-radius: .5rem;
  justify-self: center;
  width: 70%;
  margin: 0 auto;
  padding: .8rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  transition: all .3s;
  display: block;
}

@media only screen and (width <= 56.25em) {
  .booking__form--input {
    width: 85%;
    font-size: 1.5rem;
  }
}

@media only screen and (width <= 37.5em) {
  .booking__form--input {
    width: 100%;
  }
}

.booking__form--input:focus {
  border-bottom: 3px solid #618877;
  outline: none;
  box-shadow: 0 1rem 2rem #0000001a;
}

.booking__form--input:focus:invalid {
  border-bottom: 3px solid #72322a;
}

.booking__form--input::-webkit-input-placeholder {
  color: #aaa;
}

.booking__form--label {
  width: 70%;
  height: 1.7rem;
  margin: 0 auto 2rem;
  padding: 0 2rem;
  font-size: 1.1rem;
  font-weight: 300;
  transition: all .3s;
  display: block;
}

@media only screen and (width <= 37.5em) {
  .booking__form--label {
    width: 100%;
  }
}

.booking__form--label.anfrage {
  width: calc(85% + .45rem);
}

@media only screen and (width <= 56.25em) {
  .booking__form--label.anfrage {
    width: calc(92.5% + .1125rem);
  }
}

.booking__form--input:placeholder-shown + .booking__form--label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-3.5rem);
}

.booking__form--btn {
  justify-content: center;
  width: 17rem;
  height: 4.5rem;
  margin: 2rem auto;
}

.booking__form textarea {
  resize: none;
  width: calc(85% + .45rem);
  height: calc(100% - 4.7rem);
  padding: 1rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 1.25rem;
}

@media only screen and (width <= 56.25em) {
  .booking__form textarea {
    width: calc(92.5% + .1125rem);
    font-size: 1.5rem;
  }
}

@media only screen and (width <= 37.5em) {
  .booking__form textarea {
    width: 100%;
  }
}

.booking__form textarea:focus::placeholder {
  color: #0000;
}

.booking__form button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin-top: 1rem;
}

.btn--close-booking {
  color: #bf9f58;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  transition: all .2s;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.btn--close-booking:hover {
  color: #876d33;
}

.scrollbar-active ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 2px;
}

.scrollbar-active ::-webkit-scrollbar-thumb {
  background-color: #bf9f58fc;
  border-radius: 2px;
  box-shadow: 0 0 1px #ffffff80;
}

.success__body {
  min-height: 100vh;
}

.success__container {
  width: 70%;
  margin: 0 auto;
}

.success__icon {
  width: 8rem;
  height: 8rem;
  margin: 3rem auto 6rem;
  display: block;
}

.success__header {
  text-align: center;
  border-bottom: 1px solid #bf9f58;
  margin-top: 7rem;
  padding: 5rem;
}

.success__header--heading {
  color: #bf9f58;
  font-family: Birthstone Bounce, cursive;
  font-size: 6rem;
  line-height: 1;
}

.success__message {
  text-align: center;
  padding: 3rem;
}

.success__message--text {
  font-size: 1.5rem;
}

.success__mail {
  cursor: pointer;
  color: #bf9f58;
  border: none;
  font-weight: 500;
  text-decoration: underline;
}

.schulungen-placeholder {
  color: #bf9f58;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 7rem;
  padding: 10rem;
  font-family: Lato, sans-serif;
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.6;
  display: flex;
}

@media only screen and (width >= 112.5em) {
  .schulungen-placeholder {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.footer-page {
  min-height: calc(100vh - 45rem);
  margin-top: 7rem;
  padding: 5rem 5rem 2rem;
  font-size: 1.5rem;
  transition: all .3s;
}

@media only screen and (width <= 37.5em) {
  .footer-page {
    min-height: calc(100vh - 60rem);
    font-size: 2rem;
  }
}

@media only screen and (width >= 112.5em) {
  .footer-page {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.footer-page__heading {
  font-size: "Lato", sans-serif;
  font-weight: 400;
}

.footer-page__contact {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

@media only screen and (width <= 56.25em) {
  .footer-page__contact {
    display: block;
  }
}

.privacy__link {
  color: #876d33;
  font-weight: 500;
  text-decoration: none;
}

.privacy__link:hover {
  color: #bf9f58;
  cursor: pointer;
}

.privacy__subsection {
  scroll-margin-top: calc(7rem - 2px);
}

.agb__item--level-1 {
  color: #876d33;
  font-size: 2.5rem;
  font-weight: 500;
}

.agb__item--level-2 {
  font-size: 1.7rem;
  font-weight: 500;
}

.cookie-settings {
  color: #202020;
  min-height: 100vh;
  margin-top: 7rem;
  padding: 10rem;
  font-family: Lato, sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.3;
}

@media only screen and (width >= 112.5em) {
  .cookie-settings {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.cookie-settings a {
  color: #876d33;
  font-weight: 500;
  text-decoration: none;
}

.cookie-settings a:hover {
  color: #bf9f58;
  cursor: pointer;
}

.not-found-404 {
  background-image: url("bg-start.5f3871e6.jpg");
  background-position: top;
  background-size: cover;
  height: calc(100vh - 7rem);
  min-height: 70rem;
  margin-top: 7rem;
  padding: 0 0 0 7vw;
  scroll-margin-top: calc(7rem - 2px);
}

@media only screen and (width >= 112.5em) {
  .not-found-404 {
    padding: 5rem calc(50vw - 900px + 5rem);
  }
}

.not-found-404__header {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
}

.not-found-404__header--message-1 {
  color: #bf9f58;
  font-family: Birthstone Bounce, cursive;
  font-size: 22rem;
  line-height: 1;
}

@media only screen and (width <= 37.5em) {
  .not-found-404__header--message-1 {
    font-size: 20rem;
  }
}

@media only screen and (width <= 21.875em) {
  .not-found-404__header--message-1 {
    font-size: 18rem;
  }
}

.not-found-404__header--message-2 {
  width: 50vw;
  padding: 7vh 0 1rem;
  font-size: 2.7rem;
  font-weight: 300;
}

@media only screen and (width <= 37.5em) {
  .not-found-404__header--message-2 {
    width: 70vw;
  }
}

.not-found-404__header--message-main {
  width: 50vw;
  padding: 1rem 0 7vh;
  font-size: 2rem;
  font-weight: 300;
}

@media only screen and (width <= 37.5em) {
  .not-found-404__header--message-main {
    width: 70vw;
  }
}

.not-found-404__btn {
  opacity: 1;
  justify-content: center;
  width: 35rem;
  display: flex;
}

@media only screen and (width <= 37.5em) {
  .not-found-404__btn {
    width: 80%;
    font-size: 1.8rem !important;
  }
}

@media only screen and (width <= 21.875em) {
  .not-found-404__btn {
    width: 100%;
    font-size: 1.8rem !important;
  }
}

body {
  font-family: Lato, sans-serif;
  font-weight: 300;
  line-height: 1.6;
}

:root {
  --litepicker-container-months-color-bg: #fff;
  --litepicker-container-months-box-shadow-color: #ddd;
  --litepicker-footer-color-bg: #fafafa;
  --litepicker-footer-box-shadow-color: #ddd;
  --litepicker-tooltip-color-bg: #fff;
  --litepicker-month-header-color: #333;
  --litepicker-button-prev-month-color: #bf9f58;
  --litepicker-button-next-month-color: #bf9f58;
  --litepicker-button-prev-month-color-hover: #876d33;
  --litepicker-button-next-month-color-hover: #876d33;
  --litepicker-month-width: calc(var(--litepicker-day-width) * 7);
  --litepicker-month-weekday-color: #333;
  --litepicker-month-week-number-color: #9e9e9e;
  --litepicker-day-width: 6.5rem;
  --litepicker-day-color: #333;
  --litepicker-day-color-hover: #bf9f58;
  --litepicker-is-today-color: #bf9f58;
  --litepicker-is-in-range-color: #bbdefb;
  --litepicker-is-locked-color: #c9c9c9;
  --litepicker-is-start-color: #fff;
  --litepicker-is-start-color-bg: #bf9f58;
  --litepicker-is-end-color: #fff;
  --litepicker-is-end-color-bg: #bf9f58;
  --litepicker-button-cancel-color: #fff;
  --litepicker-button-cancel-color-bg: #9e9e9e;
  --litepicker-button-apply-color: #fff;
  --litepicker-button-apply-color-bg: #bf9f58;
  --litepicker-button-reset-color: #909090;
  --litepicker-button-reset-color-hover: #bf9f58;
  --litepicker-highlighted-day-color: #333;
  --litepicker-highlighted-day-color-bg: #ffeb3b;
}

@media only screen and (width <= 37.5em) {
  :root {
    --litepicker-day-width: 5rem;
  }
}

@media only screen and (width <= 21.875em) {
  :root {
    --litepicker-day-width: 4rem;
  }
}

.show-week-numbers {
  --litepicker-month-width: calc(var(--litepicker-day-width) * 8);
}

.litepicker {
  width: 100%;
  font-family: inherit;
  font-size: 1.4rem;
  display: none;
}

.litepicker button {
  background: none;
  border: none;
}

.litepicker .container__main {
  display: flex;
}

@media only screen and (width <= 56.25em) {
  .litepicker .container__main {
    justify-content: center;
  }
}

.litepicker .container__months {
  -webkit-backdrop-filter: blur(7px);
  height: 29rem;
  width: calc(var(--litepicker-month-width)  + 10px);
  background: none;
  border: 1px solid #fff6;
  border-radius: 1rem;
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 30px #0000001a;
}

@media only screen and (width <= 56.25em) {
  .litepicker .container__months {
    height: 32rem;
  }
}

.litepicker .container__months.columns-2 {
  width: calc(var(--litepicker-month-width) * 2 + 20px);
}

.litepicker .container__months.columns-3 {
  width: calc(var(--litepicker-month-width) * 3 + 30px);
}

.litepicker .container__months.columns-4 {
  width: calc(var(--litepicker-month-width) * 4 + 40px);
}

.litepicker .container__months.split-view .month-item-header .button-previous-month, .litepicker .container__months.split-view .month-item-header .button-next-month {
  visibility: visible;
}

.litepicker .container__months .month-item {
  width: var(--litepicker-month-width);
  box-sizing: content-box;
  padding: 5px;
}

.litepicker .container__months .month-item-header {
  text-align: center;
  color: var(--litepicker-month-header-color);
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 10px 5px;
  font-weight: 500;
  display: flex;
}

.litepicker .container__months .month-item-header div {
  flex: 1;
}

.litepicker .container__months .month-item-header div > .month-item-name {
  margin-right: 5px;
  font-size: 1.6rem;
}

@media only screen and (width <= 56.25em) {
  .litepicker .container__months .month-item-header div > .month-item-name {
    font-size: 2rem;
  }
}

.litepicker .container__months .month-item-header div > .month-item-year {
  padding: 0;
  font-size: 1.6rem;
}

@media only screen and (width <= 56.25em) {
  .litepicker .container__months .month-item-header div > .month-item-year {
    font-size: 2rem;
  }
}

.litepicker .container__months .month-item-header .reset-button {
  color: var(--litepicker-button-reset-color);
}

.litepicker .container__months .month-item-header .reset-button > svg {
  fill: var(--litepicker-button-reset-color);
}

.litepicker .container__months .month-item-header .reset-button * {
  pointer-events: none;
}

.litepicker .container__months .month-item-header .reset-button:hover {
  color: var(--litepicker-button-reset-color-hover);
}

.litepicker .container__months .month-item-header .reset-button:hover > svg {
  fill: var(--litepicker-button-reset-color-hover);
}

.litepicker .container__months .month-item-header .button-previous-month, .litepicker .container__months .month-item-header .button-next-month {
  visibility: hidden;
  cursor: pointer;
  border-radius: 3px;
  padding: 3px 5px;
  text-decoration: none;
  transition: color .3s, border .3s;
}

.litepicker .container__months .month-item-header .button-previous-month *, .litepicker .container__months .month-item-header .button-next-month * {
  pointer-events: none;
}

.litepicker .container__months .month-item-header .button-previous-month {
  color: var(--litepicker-button-prev-month-color);
}

.litepicker .container__months .month-item-header .button-previous-month > svg, .litepicker .container__months .month-item-header .button-previous-month > img {
  fill: var(--litepicker-button-prev-month-color);
}

.litepicker .container__months .month-item-header .button-previous-month:hover {
  color: var(--litepicker-button-prev-month-color-hover);
}

.litepicker .container__months .month-item-header .button-previous-month:hover > svg {
  fill: var(--litepicker-button-prev-month-color-hover);
}

.litepicker .container__months .month-item-header .button-next-month {
  color: var(--litepicker-button-next-month-color);
}

.litepicker .container__months .month-item-header .button-next-month > svg, .litepicker .container__months .month-item-header .button-next-month > img {
  fill: var(--litepicker-button-next-month-color);
}

.litepicker .container__months .month-item-header .button-next-month:hover {
  color: var(--litepicker-button-next-month-color-hover);
}

.litepicker .container__months .month-item-header .button-next-month:hover > svg {
  fill: var(--litepicker-button-next-month-color-hover);
}

.litepicker .container__months .month-item-weekdays-row {
  color: var(--litepicker-month-weekday-color);
  justify-content: flex-start;
  justify-self: center;
  margin-bottom: .5rem;
  font-size: 1.3rem;
  font-weight: 300;
  display: flex;
}

@media only screen and (width <= 56.25em) {
  .litepicker .container__months .month-item-weekdays-row {
    font-size: 1.6rem;
  }
}

.litepicker .container__months .month-item-weekdays-row > div {
  width: var(--litepicker-day-width);
  text-align: center;
  flex: 1;
  padding: 5px 0;
  font-size: 90%;
}

.litepicker .container__months .month-item:first-child .button-previous-month, .litepicker .container__months .month-item:last-child .button-next-month {
  visibility: visible;
}

.litepicker .container__months .month-item.no-previous-month .button-previous-month, .litepicker .container__months .month-item.no-next-month .button-next-month {
  visibility: hidden;
}

.litepicker .container__days {
  text-align: center;
  box-sizing: content-box;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-self: center;
  display: flex;
}

.litepicker .container__days > div, .litepicker .container__days > a {
  width: var(--litepicker-day-width);
  padding: 5px 0;
}

.litepicker .container__days .day-item {
  color: var(--litepicker-day-color);
  text-align: center;
  cursor: default;
  border-radius: 3px;
  font-size: 1.2rem;
  font-weight: 300;
  text-decoration: none;
  transition: color .3s, border .3s;
}

@media only screen and (width <= 56.25em) {
  .litepicker .container__days .day-item {
    font-size: 1.4rem;
  }
}

.litepicker .container__days .day-item:hover {
  color: var(--litepicker-day-color-hover);
  box-shadow: inset 0 0 0 1px var(--litepicker-day-color-hover);
}

.litepicker .container__days .day-item.is-today {
  color: var(--litepicker-is-today-color);
  font-weight: 700;
}

.litepicker .container__days .day-item.is-locked {
  color: var(--litepicker-is-locked-color);
}

.litepicker .container__days .day-item.is-locked:hover {
  color: var(--litepicker-is-locked-color);
  box-shadow: none;
  cursor: default;
}

.litepicker .container__days .day-item.is-in-range {
  background-color: var(--litepicker-is-in-range-color);
  border-radius: 0;
}

.litepicker .container__days .day-item.is-start-date {
  color: var(--litepicker-is-start-color);
  background-color: var(--litepicker-is-start-color-bg);
  border-radius: 5px 0 0 5px;
}

.litepicker .container__days .day-item.is-start-date.is-flipped {
  border-radius: 0 5px 5px 0;
}

.litepicker .container__days .day-item.is-end-date {
  color: var(--litepicker-is-end-color);
  background-color: var(--litepicker-is-end-color-bg);
  border-radius: 0 5px 5px 0;
}

.litepicker .container__days .day-item.is-end-date.is-flipped {
  border-radius: 5px 0 0 5px;
}

.litepicker .container__days .day-item.is-start-date.is-end-date {
  border-radius: 5px;
}

.litepicker .container__days .day-item.is-highlighted {
  color: var(--litepicker-highlighted-day-color);
  background-color: var(--litepicker-highlighted-day-color-bg);
}

.litepicker .container__days .week-number {
  color: var(--litepicker-month-week-number-color);
  justify-content: center;
  align-items: center;
  font-size: 85%;
  display: flex;
}

.litepicker .container__footer {
  text-align: right;
  background-color: var(--litepicker-footer-color-bg);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0 5px;
  padding: 10px 5px;
}

.litepicker .container__footer .preview-date-range {
  margin-right: 10px;
  font-size: 90%;
}

.litepicker .container__footer .button-cancel {
  background-color: var(--litepicker-button-cancel-color-bg);
  color: var(--litepicker-button-cancel-color);
  border: 0;
  border-radius: 3px;
  padding: 3px 7px 4px;
}

.litepicker .container__footer .button-cancel * {
  pointer-events: none;
}

.litepicker .container__footer .button-apply {
  background-color: var(--litepicker-button-apply-color-bg);
  color: var(--litepicker-button-apply-color);
  border: 0;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px 7px 4px;
}

.litepicker .container__footer .button-apply:disabled {
  opacity: .7;
}

.litepicker .container__footer .button-apply * {
  pointer-events: none;
}

.litepicker .container__tooltip {
  background-color: var(--litepicker-tooltip-color-bg);
  white-space: nowrap;
  pointer-events: none;
  visibility: hidden;
  border-radius: 4px;
  margin-top: -4px;
  padding: 4px 8px;
  font-size: 11px;
  position: absolute;
}

.litepicker .container__tooltip:before {
  content: "";
  border-top: 5px solid #0000001f;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
}

.litepicker .container__tooltip:after {
  border-top: 4px solid var(--litepicker-tooltip-color-bg);
  content: "";
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
}
/*# sourceMappingURL=404.d64ba013.css.map */
